export default {
  "aboutCanstructionHistory": "Canstruction®️ 歷史",
  "aboutCanstructionHistoryMessage1": "Canstruction®️ 是一個透過罐頭建築概念去籌集食物的國際慈善機構，其宗旨為「一罐一膳心，將愛與關懷送給社會上有需要人士」。透過舉辦以罐頭堆砌成精美而具創意的罐頭作品比賽或展覽，喚起公眾關注基層人士面對的飢餓問題。比賽或展覽結束後，主辦機構會捐贈所有罐頭予食物銀行、慈善團體及有需要人士。\n自1992年成立以來，Canstruction®️ 在全球170多個城市舉辦了設計和建造比賽，有超過30,000名義工參與，捐贈了近過百萬磅罐頭食品，已發展成為世界上最大的食物銀行及捐贈食物活動之一。",
  "aboutCanstructionHongKong": "關於Canstruction®️ Hong Kong",
  "aboutCanstructionHongKongMessage": "惜食堂於 2012 年成功獲 Canstruction®️ 授權為首個亞洲區主辦機構，透過舉辦以罐頭堆砌成精美而具創意的罐頭作品展覽，喚起公眾關注基層人士面對的飢餓問題，是惜食堂重要的年度籌款活動。展覽結束後，所有罐頭將會用於惜食堂製作膳食或轉贈予惜食堂慈善合作機構伙伴及有需要人士，是個展現大家創作力、發揮團隊精神及履行社會責任的慈善盛事。",

  "aboutFoodAngelCanstructionStation": "罐頭收集點",
  "aboutFoodAngelMission": "宗旨理念",
  "aboutFoodAngelMissionBackground": "背景",
  "aboutFoodAngelMissionLinkToFoodAngel": "了解更多惜食堂工作",
  "aboutFoodAngelMissionMessage1": "「惜食堂」是小寶慈善基金於2011年開展的首個食物回收及援助計劃，宗旨為「停止浪費、 解決飢餓、以愛相連」。",
  "aboutFoodAngelMissionMessage2": "惜食堂的團隊致力向本地飲食界回收仍可安全食用之剩餘食物，通過嚴格的食物安檢程序，再經中央廚房烹煮成營養均衡的膳食或預備成食物包，最後由物流團隊免費派發給社會上需要食物援助的人士。",
  "aboutFoodAngelMissionMessage3": "現時，惜食堂平均每星期回收45噸仍可食用的剩餘食物，每天自家製作超過20,000份營養均衡的膳食，並拹助提供約11,000份其他膳食及食物包，免費給予社會上有需要食物援助的人士。",
  "aboutFoodAngelMissionMessage4": "惜食堂一向重視教育項目，努力推動惜食文化及宣揚關愛訊息。",
  "aboutFoodAngelMissionTitle": "停止浪費 解決飢餓 以愛相連",
  "aboutFoodAngelMissionVision": "我們的願景",
  "aboutFoodAngelMissionVisionMessage": "讓施與受同享豐盛生命",

  "applicationCorporateSponsorTitle": "申請成為企業贊助商",

  "applicationProfessionalTeamTitle": "申請成為專業團隊",

  "btnAddCampaign": "添加更多",

  "btnBack": "返回",

  "btnCancel": "取消",

  "btnClose": "關閉",

  "btnDetail": "詳情",

  "btnDonationLink": "分享網頁連結",
  "btnDonationNow": "立即捐款",

  "btnFooterBackToTop": "返回最頂",

  "btnForgotPassword": "忘記密碼",

  "btnFundraiserPortal": "籌款網頁管理",

  "btnLearnMore": "了解更多",

  "btnLogin": "登入",
  "btnLoginFundraiser": "登入籌款網頁",
  "btnLoginLong": "為惜食堂籌款",

  "btnLogout": "登出",

  "btnNext": "下一步",

  "btnNotRegister": "未註冊為籌款者?",

  "btnRegisterFundraiser": "註冊籌款網頁",

  "btnResendEmail": "重發郵件",

  "btnSubmit": "提交",

  "btnSupportDonate": "支持 & 捐款",

  "campaignNotFound": "暫無數據",
  "campaignNotName": "沒有名稱",

  "cansUsedText": "罐頭使用",

  "changePassworInfoOldPassword": "舊密碼",

  "changePasswordIncorrectOldPassword": "舊密碼不正確!",
  "changePasswordInfoConfirmPassword": "再次確認密碼",
  "changePasswordInfoConfirmPasswordErrorRequired": "請再次輸入密碼!",
  "changePasswordInfoConfirmPasswordMatch": "請確認新密碼是否正確!",
  "changePasswordInfoNewPassword": "新密碼",

  "contactUsEmail": "電郵",
  "contactUsFacebook": "Facebook",
  "contactUsInstagram": "Instagram",
  "contactUsPhone": "電話",
  "contactUsWebsite": "Website",

  "contanceUsOfficeHours": "辦公時間",

  "donationHistoryDetailAmount": "捐款金額",
  "donationHistoryDetailCode": "編號",
  "donationHistoryDetailDate": "日期",
  "donationHistoryDetailDonorName": "捐款者",
  "donationHistoryDetailStatus": "狀態",
  "donationHistoryDonationAmount": "最早捐款金額",
  "donationHistoryLastest": "最新記錄在頂部",
  "donationHistoryListTitle": "捐款記錄",
  "donationHistoryOldest": "最早記錄在頂部",

  "donationPaymentBankCode": "編號",
  "donationPaymentBankMessage": "請前往郵箱完成捐款流程!",
  "donationPaymentBankSuccess": "捐款記錄成功建立",
  "donationPaymentFailedMessage": "抱歉, 支付失敗。請返回重新再試",
  "donationPaymentPayMeBottom": "在交易完成前請不要關閉此頁面",
  "donationPaymentPayMeStep1": "打開 PayMe 應用程式",
  "donationPaymentPayMeStep2": "掃描 PayCode 以授權付款",
  "donationPaymentPayMeStep3": "在 PayMe 完成交易後，請留在此頁面等待確認",
  "donationPaymentPayMeStepTitle": "如何用 PayMe 付款？",
  "donationPaymentPayMeTitle": "請用 PayMe 掃描此 PayCode",
  "donationPaymentPaymeWaitingPaymentMessage": "等待支付中...",
  "donationPaymentPaypalSuccess": "PayPal 付款成功",
  "donationPaymentPaypalWaiting": "PayPal 付款處理中, 請勿關閉此視窗...",
  "donationPaymentSuccessMessage": "支付成功, 您可以檢查您的郵箱找到這筆捐款相關的記錄和收據",
  "donationPaymentTitle": "處理支付中",

  "donationTitle": "捐款",

  "donorText": "捐贈者",

  "emailIsInvalidMessage": "無效郵箱!",
  "emailIsVerifiedMessage": "郵箱已驗證!",

  "exhibitionWorkTeam": "隊伍",
  "exhibitionWorkTitle": "作品",

  "faqAnswerTitle": "答案",

  "faqQuestionTitle": "問題",

  "fundraiserCampaignCorporateTitle": "企業",
  "fundraiserCampaignIndividualTitle": "個人",
  "fundraiserCampaignSchoolTitle": "Canstruction HK Junior",

  "fundraiserCorporateTitle": "企業",

  "fundraiserExpandText": "展開",

  "fundraiserHomeNotLoginGoRegsiterTitle": "立即建立你的專屬籌款網頁",

  "fundraiserIndividualTitle": "個人",

  "fundraiserPendingText": "審批中",

  "fundraiserPortalAccountDonationCompleted": "首次捐款完成",
  "fundraiserPortalAccountDonationNow": "立即捐款 HK${{amount}} 建立你的籌款網頁",
  "fundraiserPortalAccountRegistionCompanyName": "公司名稱",
  "fundraiserPortalAccountRegistionSuccess": "信息已成功提交。感謝您的註冊！",
  "fundraiserPortalCampaignDetailSubmitSuccess": "內容已成功提交,經審批後籌款網頁將會正式啟動,感謝您的善心支持!",
  "fundraiserPortalCampaignDetailSubmitTitle": "您的籌款網頁正在審批中",
  "fundraiserPortalCampaignInfoDescriptionCN": "簡介 (中文)",
  "fundraiserPortalCampaignInfoDescriptionEN": "簡介 (英文)",
  "fundraiserPortalCampaignInfoProfileAvatar": "作品頭像",
  "fundraiserPortalCampaignInfoQuantity": "罐頭數量",
  "fundraiserPortalCampaignInfoQuantityErrorRequired": "請輸入罐頭數量!",
  "fundraiserPortalCampaignInfoWebsiteTerm": "我已仔細閲讀並同意本網站的使用條款(包括服務佣金)和隱私政策。",
  "fundraiserPortalCampaignInfoWithCanstuction": "有作品",
  "fundraiserPortalCampaignInfoWithoutCanstuction": "無作品",
  "fundraiserPortalCampaignInfoWorksImages": "Canstruction 作品圖片",
  "fundraiserPortalCampaignInfoWorksImagesSize": "以上照片的大小不應超過2MB。",
  "fundraiserPortalCampaignInfoWorksVideo": "Canstruction 作品視頻",
  "fundraiserPortalCampaignInfoWorksVideoSize": "以上照片的大小不應超過100MB。",
  "fundraiserPortalCampaignName": "名稱",
  "fundraiserPortalCampaignProfileImageType": "只能上傳JPG/PNG的文件!",
  "fundraiserPortalCampaignProfileWorks": "作品圖片",
  "fundraiserPortalCampaignQuantity": "數量",
  "fundraiserPortalInfoCampaignNameCN": "網頁標題 (中文)",
  "fundraiserPortalInfoCampaignNameEN": "網頁標題 (英文)",
  "fundraiserPortalMenuAccountInformation": "帳號管理",
  "fundraiserPortalMenuCampaignSetting": "籌款網頁設定",
  "fundraiserPortalMenuChangePassword": "修改密碼",
  "fundraiserPortalMenuDonationHistory": "捐款歷史",
  "fundraiserPortalMenuLogout": "登出",
  "fundraiserPortalSettingTitle": "籌款網頁管理",

  "fundraiserResetEmailErrorRequired": "請輸入郵箱!",
  "fundraiserResetEmailSend": "郵件發送成功, 請前往郵箱檢查重置郵件!",
  "fundraiserResetInfoEmail": "郵箱",
  "fundraiserResetInfoNewPassword": "新密碼",
  "fundraiserResetInfoNewPasswordErrorRequired": "請輸入新密碼!",
  "fundraiserResetPasswordTitle": "重置密碼",
  "fundraiserResetRelogin": "密碼修改成功,請重新登錄!",
  "fundraiserResetResendEmailTitle": "重發郵件",

  "hasBeenFundedText": "已籌得",

  "homeCampaignBtnBackIt": "支持",
  "homeCampaignDateDay": "天",
  "homeCampaignDateHour": "小時",
  "homeCampaignDateMessage": "立即投票和支持!",
  "homeCampaignDatePostfix": "就開始",

  "homeCanstuctionJuniorTitle": "Canstruction HK Junior",
  "homeCanstuctionJuniorTitleSecond": "按下愛心投票給你最心儀的學校作品",

  "homeOurFundraiserCorporateCaption": "企業",
  "homeOurFundraiserIndividualCaption": "個人",
  "homeOurFundraiserTitle": "籌款者",

  "homeShareDonationLinkTitle": "分享您的鏈接",

  "homeVotedText": "個投票",

  "invalidTokenMessage": "無效Token!",

  "lastestText": "最新",

  "loginEmail": "登錄郵箱",
  "loginEmailErrorRequired": "請輸入郵箱!",

  "loginErrorIncorrectPassword": "密碼錯誤!",
  "loginErrorInvalidUserName": "郵箱錯誤!",
  "loginErrorPleaseVerifyYourEmail": "請先通過郵件驗證!",

  "loginPassword": "登錄密碼",
  "loginPasswordErrorRequired": "請輸入登錄密碼!",

  "loginSuccess": "登錄成功!",

  "loginTitle": "登入",

  "megaDetailPriceTopMessage": "*配捐總額上限爲100萬港元。",

  "menuAboutCanstruction": "關於Canstruction",
  "menuAboutFoodAngel": "關於惜食堂",
  "menuAboutUs": "關於我們",

  "menuApplicationForCanstruction": "申請Cantstruction {{year}}展覽",

  "menuCanstructionExhibition": "《豐膳罐愛心》展覽",
  "menuCanstructionExhibitionCorporate": "企業",
  "menuCanstructionExhibitionIndividual": "個人",
  "menuCanstructionJunior": "Canstruction HK Junior",

  "menuContactUs": "聯絡我們",

  "menuDisclaimer": "免責聲明",

  "menuFaq": "常見問題",

  "menuFundraisers": "籌款網頁",

  "menuHome": "主頁",

  "menuMore": "更多",

  "menuSponsors": "支持機構",

  "menuSupportUs": "支持我們",

  "menuTc": "使用條款",

  "oldestText": "最早",

  "organizerText": "學校",

  "pageNotFound": "頁面不存在!",

  "paymeIsTimeout": "支付超時,請返回重新支付",

  "raisedText": "已籌",

  "registerFundraiserCategory": "類別",
  "registerFundraiserCategoryCorporate": "企業 (捐款 HK${{amount}} 或以上可申請)",
  "registerFundraiserCategoryIndividual": "個人 (捐款 HK${{amount}} 或以上可申請)",
  "registerFundraiserCategorySchool": "Canstruction HK Junior",
  "registerFundraiserCheckBox": "本人已仔細閲讀並知悉上述個人資料收集聲明。",
  "registerFundraiserCompanyAndSchoolName": "公司或學校名稱",
  "registerFundraiserCompanyAndSchoolNameErrorRequired": "請輸入公司或學校名稱",
  "registerFundraiserConfirmPassword": "確認密碼",
  "registerFundraiserConfirmPasswordErrorRequired": "請再次輸入密碼",
  "registerFundraiserConfirmPasswordNotMatch": "兩次密碼不一致",
  "registerFundraiserContactNumber": "聯絡電話",
  "registerFundraiserContactNumberErrorRequired": "請輸入聯絡電話",
  "registerFundraiserContactPerson": "聯絡人",
  "registerFundraiserContactPersonErrorRequired": "請輸入聯絡人",
  "registerFundraiserEmail": "電郵地址",
  "registerFundraiserEmailErrorRequired": "請輸入電郵地址",
  "registerFundraiserErrorDuplicateEmail": "郵箱已存在!",
  "registerFundraiserErrorInvalidCompanyName": "無效公司/學校名稱",
  "registerFundraiserErrorResendVerifyEmail": "激活郵件已過期，是否重新發送",
  "registerFundraiserErrorWaitingForVerify": "郵箱在待激活狀態，請前往郵箱完成激活",
  "registerFundraiserLanguage": "語言",
  "registerFundraiserPassword": "登錄密碼",
  "registerFundraiserPasswordErrorRequired": "請輸入密碼",
  "registerFundraiserSuccess": "註冊成功，請前往郵箱進行驗証!",

  "registrationTitle": "註冊",

  "resendEmailSuccess": "郵件已重新發送，請到郵箱驗證!",

  "submitFailedMessage": "提交失敗",

  "submitSuccessMessage": "提交成功",

  "supportUsBasic": " (基本)",
  "supportUsBottomNewsCheckbox": "我不想收到任何來自惜食堂的計畫或活動訊息。",
  "supportUsBottomTCCheckbox": "本人已仔細閲讀並知悉上述個人資料收集聲明。",
  "supportUsBottomTCMessage1": "惜食堂承諾遵守個人資料（私隱）條例的規定，確保您的個人資料準確及安全保存。 您的個人資料（包括姓名、電話號碼、電子郵件及郵寄地址）將用於發出捐款收據、提供募款活動報告/資料及處理捐款管理。 您提交的所有個人資訊都是自願的。",
  "supportUsBottomTCMessage2": "未經您事先同意，我們不會向任何其他方揭露我們收集的有關您的資訊。 您可以隨時應您的要求，致電 2801 5333 與我們聯繫，查閲、更正和停止我們為上述目的使用您的個人資料。",
  "supportUsBottomTCTitle": "個人資料收集",
  "supportUsCannedFoodUnit": "個罐頭",
  "supportUsDonationAmountMin": "捐款的最少金額為 HK$ {{amount}}",
  "supportUsDonationAmountOther": "其他",
  "supportUsDonationAmountOtherAmountErrorRequired": "請輸入捐款金額",
  "supportUsDonationTypeCorporate": "公司捐款",
  "supportUsDonationTypeIndividual": "個人捐款",
  "supportUsErrorMessageInternalServerError": "抱歉，系統發生錯誤。請稍後重試或選擇其他支付方式",
  "supportUsErrorMessageUnknown": "發生未知錯誤，請稍後重試或選擇其他支付方式",
  "supportUsFirstNameLength": "不能超過20字符",
  "supportUsInfoAddress": "郵寄地址 (室/樓/座)",
  "supportUsInfoAddressBuilding": "樓",
  "supportUsInfoAddressCountry": "國家/城市",
  "supportUsInfoAddressDistrict": "地區",
  "supportUsInfoAddressErrorRequired": "請輸入郵寄地址",
  "supportUsInfoAddressStreet": "街道",
  "supportUsInfoContactNumber": "聯絡電話",
  "supportUsInfoContactNumberErrorRequired": "請輸入正確的聯絡電話",
  "supportUsInfoEmail": "電郵地址",
  "supportUsInfoEmailErrorRequired": "請輸入電郵地址",
  "supportUsInfoEnglishFirstName": "英文名字",
  "supportUsInfoEnglishSurname": "英文姓氏",
  "supportUsInfoTitle": "稱謂",
  "supportUsInfoTitleMiss": "小姐",
  "supportUsInfoTitleMr": "先生",
  "supportUsInfoTitleMrs": "太太",
  "supportUsPaymentTypeBankTransfer": "銀行轉帳",
  "supportUsPaymentTypeCheque": "支票",
  "supportUsReceiptChoiceEmail": "電郵接收電子收據",
  "supportUsReceiptChoiceMail": "郵寄實體收據",
  "supportUsReceiptChoiceNo": "沒有收據",
  "supportUsReceiptChoiceReceiptName": "收據抬頭名稱",
  "supportUsReceiptChoiceReceiptNameErrorRequired": "請輸入收據抬頭名稱",
  "supportUsTitleDonationAmount": "捐款金額",
  "supportUsTitleDonationAmountErrorRequired": "請選擇捐款金額",
  "supportUsTitleDonationInformation": "捐款資料",
  "supportUsTitleDonationMethod": "捐款方式",
  "supportUsTitleDonationMethodErrorRequired": "請選擇捐款方式",
  "supportUsTitleDonationNameOfCoprationErrorRequired": "請輸入公司名稱",
  "supportUsTitleDonationNameOfCorporation": "公司名稱",
  "supportUsTitleDonationType": "捐款類別",
  "supportUsTitleDonationTypeErrorRequired": "請選擇捐款類別",
  "supportUsTitleReceiptChoice": "收據選項",

  "tokenExpiredMessage": "Token過期!",

  "top10MostCashDonated": "最多捐款前十",
  "top10MostPopular": "最受歡迎前十",

  "uploadText": "上傳",

  "userNotFoundMessage": "用户不存在!",

  "votedText": "投票"
}