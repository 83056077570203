import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import DonationPayment from './DonationPayment';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import { store } from './reducers'
import {
    BrowserRouter,
    Routes,
    Route,
    Link,
} from "react-router-dom";
import {ConfigProvider } from 'antd';
import zhTW from 'antd/es/locale/zh_TW';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Provider store={store}>
          <BrowserRouter>
              <ConfigProvider locale={zhTW}
                              componentSize="large"
                              theme={{
                                  token: {
                                      fontSize: 20,
                                  },
                              }}
              >
                  <Routes>
                      <Route path="/donation-payment/*" element={<DonationPayment />}></Route>
                      <Route path="/" element={<App />}></Route>
                  </Routes>
              </ConfigProvider>
          </BrowserRouter>
      </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
