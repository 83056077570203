import './App.css';
import React, {useState, useEffect} from 'react'
import {useParams, useNavigate, useLocation} from 'react-router-dom'
import {
  Dropdown,
  ConfigProvider,
  Space,
  Radio,
  DatePicker,
  InputNumber,
  message,
  Typography,
  Col,
  Row,
  Form,
  Input,
  Spin,
  Flex,
  Button
} from 'antd'
import * as ToolRequest from './tools/ToolRequest'

const {Title, Text} = Typography

const formLayout = {
  labelCol: {
    md: {span: 5},
    sm: {span: 24},
  },
  wrapperCol: {
    md: {span: 16},
  },
}
const formTailLayout = {
  wrapperCol: {
    md: {offset: 5, span: 16},
  },
}

export default function App() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const onFinish = async (values) => {
    try {
      setLoading(true)
      console.dir(values)
      const res = await ToolRequest.request('POST','/donation-record',{amount:values.amount})
      console.dir(res)
      navigate(`/donation-payment?donationRecordId=${res.donationRecordId}&paymentType=PAYME`,{state: {
          isMobile:false,
          paymeUrl:res.link,
        }})
    } catch (e) {
      console.dir(e)
      message.error('未知錯誤，請聯繫管理員')
    } finally {
      setLoading(false)
    }
  }
  return (
      <div className="App">
        <Spin spinning={loading}>
        <Row>
          <Col span={24}>
            <Flex justify="center">
              <Title level={5}>請選擇金額後點擊提交，進入二維碼捐款頁麵</Title>
            </Flex>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form
                {...formLayout}
                // form={form}
                name="basic"
                onFinish={onFinish}
            >
              <Form.Item
                  label="金額"
                  name="amount"
                  rules={[
                    {
                      required: true
                    },
                  ]}
              >
                <Radio.Group>
                  <Space>
                    <Radio value="100.81">100.81</Radio>
                    <Radio value="200">200</Radio>
                    <Radio value="300">300</Radio>
                    <Radio value="500">500</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
              <Form.Item {...formTailLayout}>
                <Button type="primary" htmlType="submit" block>
                  提交
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        </Spin>
      </div>
  );
}
