import { configureStore } from '@reduxjs/toolkit';
import { i18nReducer, loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';

import translations from '../locales';

export const store = configureStore({
  reducer: {
    i18n: i18nReducer,
  },
});

store.dispatch(loadTranslations(translations));
store.dispatch(setLocale('zh-tw'));
syncTranslationWithStore(store)
