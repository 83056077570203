import "./DonationPayment.css";
import {
    Modal,
    Button,
    Image,
    Typography,
    Space,
    Divider,
    Col,
    Row,
    Avatar,
} from "antd";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { CheckCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { I18n } from 'react-redux-i18n';
import * as ToolRequest from './tools/ToolRequest'

import paymeLogo from "./images/payment-payme-header.png";

function DonationPayment() {
    const navigate = useNavigate();
    const location = useLocation();
    console.dir(location)
    const [params] = useSearchParams();
    const timeRef = useRef();
    const timeoutRef = useRef(); // 60s後顯示取消支付
    const timeoutErrorRef = useRef(); // 10分鍾後停止查詢並且失敗
    const [isOut, setIsOut] = useState(false); // 是否超時了
    const [cancelBtn, setCancelBtn] = useState(false);
    const [isCancel, setIsCancel] = useState(false);

    const cancelRef = useRef(false);

    const [pathParam, setPathParam] = useState(); // 路径数据
    const [status, setStatus] = useState(null); // 支付状态

    async function searchDonationFunc(donationRecordId) {
        try {
            if (cancelRef.current) return;
            let res = await ToolRequest.request('GET',`/donation-record/${donationRecordId}`);
            setStatus(res.status);

            if (res.status === "COMPLETED") {
                clearTimeout(timeRef.current);
                clearTimeout(timeoutRef.current);
                clearTimeout(timeoutErrorRef.current);
                timeRef.current = null;
                timeoutRef.current = null;
                timeoutErrorRef.current = null;
                return;
            }
            clearTimeout(timeRef.current);
            timeRef.current = setTimeout(() => {
                searchDonationFunc(donationRecordId);
            }, 1000);
        } catch (error) {
            console.dir(error)
            timeRef.current = null;
            timeoutRef.current = null;
            timeoutErrorRef.current = null;
        }
    }

    useEffect(() => {
        (async () => {
            if (timeRef.current) {
                clearTimeout(timeRef.current);
            }
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            if (timeoutErrorRef.current) {
                clearTimeout(timeoutErrorRef.current);
            }
            let donationRecordId = params.get("donationRecordId");
            let paymentType = params.get("paymentType");
            setPathParam({
                donationRecordId,
                paymentType
            });
            if (
                donationRecordId &&
                (paymentType === "PAYME" || paymentType === "PAYPAL")
            ) {
                // 查询结果
                searchDonationFunc(donationRecordId);
                if (paymentType === "PAYME") {
                    //一分鍾後可以取消
                    timeoutRef.current = setTimeout(() => {
                        setCancelBtn(true);
                    }, 60 * 1000);

                    timeoutErrorRef.current = setTimeout(function () {
                        cancelRef.current = true;
                        setIsOut(true);
                    }, 10 * 60 * 1000);
                }
            }
        })();
    }, []);

    async function goToTarget() {
        navigate('/', {
            replace: true,
        });
    }

    // 回退
    async function cancelPaymeHandler() {
        clearTimeout(timeRef.current);
        clearTimeout(timeoutRef.current);
        clearTimeout(timeoutErrorRef.current);
        timeoutErrorRef.current = null;
        timeRef.current = null;
        timeoutRef.current = null;
        cancelRef.current = true;
        window.history.back();
    }
    return (
        <>
            <Modal
                className="payment-modal"
                keyboard={false}
                centered
                closeIcon={false}
                open={true}
                width={800}
                title={
                    <div
                        style={{
                            textAlign: "center",
                        }}
                    >
                        {I18n.t("donationPaymentTitle")}
                    </div>
                }
                footer={
                    status === "COMPLETED"
                        ? [
                            <div
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                <Button type="primary" onClick={goToTarget}>
                                    {I18n.t("btnBack")}
                                </Button>
                            </div>,
                        ]
                        : null
                }
            >
                {pathParam?.paymentType === "PAYME" ? (
                    <div>
                        <Image src={paymeLogo} preview={false} height={45} />
                    </div>
                ) : null}
                <div className="donation-payment-content">
                    <div>
                        {pathParam?.paymentType === "PAYME" ? (
                            <>
                                {status === "PENDING" ? (
                                    <>
                                        {location?.state?.isMobile ? (
                                            <Space direction={"vertical"}>
                                                <Typography.Text>
                                                    {isOut
                                                        ? I18n.t("paymeIsTimeout")
                                                        : I18n.t("donationPaymentPaymeWaitingPaymentMessage")}
                                                </Typography.Text>
                                                {cancelBtn ? (
                                                    <Button
                                                        type="primary"
                                                        shape="round"
                                                        danger
                                                        onClick={cancelPaymeHandler}
                                                    >
                                                        {I18n.t("btnCancel")}
                                                    </Button>
                                                ) : null}
                                            </Space>
                                        ) : (
                                            <Space direction={"vertical"}>
                                                <h3>{I18n.t("donationPaymentPayMeTitle")}</h3>
                                                <div className="close-out-icon">
                                                    {isOut ? <CloseOutlined /> : null}
                                                </div>

                                                <Image
                                                    src={location?.state?.paymeUrl}
                                                    preview={false}
                                                    height={320}
                                                    className={[isOut ? "payme-code-img" : null]}
                                                ></Image>

                                                <Typography.Text type="secondary">
                                                    {isOut
                                                        ? I18n.t("paymeIsTimeout")
                                                        : I18n.t("donationPaymentPayMeBottom")}
                                                </Typography.Text>
                                                {cancelBtn ? (
                                                    <Button
                                                        type="primary"
                                                        shape="round"
                                                        danger
                                                        onClick={cancelPaymeHandler}
                                                    >
                                                        {I18n.t("btnCancel")}
                                                    </Button>
                                                ) : null}

                                                <Divider
                                                    style={{
                                                        marginBottom: 8,
                                                        marginTop: cancelBtn ? 8 : "24px",
                                                    }}
                                                />
                                                <div style={{ textAlign: "left", marginBottom: 16 }}>
                                                    {I18n.t("donationPaymentPayMeStepTitle")}
                                                </div>
                                                <Row gutter={1}>
                                                    <Col span={8}>
                                                        <Space align="start">
                                                            <Avatar size="small">1</Avatar>
                                                            <div style={{ textAlign: "left" }}>
                                                                {I18n.t("donationPaymentPayMeStep1")}
                                                            </div>
                                                        </Space>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Space align="start">
                                                            <Avatar size="small">2</Avatar>
                                                            <div style={{ textAlign: "left" }}>
                                                                {I18n.t("donationPaymentPayMeStep2")}
                                                            </div>
                                                        </Space>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Space align="start">
                                                            <Avatar size="small">3</Avatar>
                                                            <div style={{ textAlign: "left" }}>
                                                                {I18n.t("donationPaymentPayMeStep3")}
                                                            </div>
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            </Space>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {/* 支付失敗, 預留之後使用 */}
                                        {/*<div style={{ fontSize: 120, color: '#b52a2a' }}>
                      <CloseCircleOutlined />
                    </div>*/}
                                        <div style={{ fontSize: 120, color: "#3cb52a" }}>
                                            <CheckCircleOutlined />
                                        </div>
                                        <Typography.Title level={5} style={{ marginBottom: 24 }}>
                                            {I18n.t("donationPaymentSuccessMessage")}
                                        </Typography.Title>
                                    </>
                                )}
                            </>
                        ) : null}
                    </div>
                </div>
            </Modal>
        </>
    );
}
export default DonationPayment;
